

























import { Component, Vue } from 'vue-property-decorator';
import ImportUiStateModule from '@/store/modules/ImportUiStateModule';

@Component
export default class ImportFailedPage extends Vue {
  get importResult() {
    return ImportUiStateModule.importResult;
  }
}
